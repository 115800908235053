<template>
   <div class="cont">
      <!-- 面包屑 -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>营销云</el-breadcrumb-item>
            <el-breadcrumb-item>运营</el-breadcrumb-item>
            <el-breadcrumb-item :to="`/activity_list/PUBLICACCOUNT?msgType=${ msgType }`">{{ msgType === 'MASS' ? '公众号' : '微信' }}群发活动</el-breadcrumb-item>
            <el-breadcrumb-item>{{ action === 'add' ? '创建' : action === 'edit' ? '编辑' : '复制'}}{{ msgType === 'MASS' ? '公众号' : '微信' }}群发活动</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <!-- 返回页头 -->
      <el-page-header @back="$router.go(-1)" :content="`${ action === 'add' ? '创建' :  action === 'edit' ?  '编辑'  : '复制' }${ msgType === 'MASS' ? '公众号' : '微信' }群发活动`"></el-page-header>
      <!-- 主体内容 -->
      <div class="cont-form-box">
         <!-- 步骤条 -->
         <el-steps :active="active" simple>
            <el-step title="01.活动基础配置"></el-step>
            <el-step title="02.活动内容预览"></el-step>
         </el-steps>
         <!-- 第一步 -->
         <div class="first-step" v-show="active === 0">
            <activity-config @nextStep="nextStep">
               <template v-slot:pushRule>
                  <el-form-item label="推送规则">
                     <div v-if="msgType === 'CUSTOMER'">
                        <el-radio @change="changePushRule" v-model="ruleForm.pushRule" label="ONCE">针对 48 小时内互动过的用户，仅发送一次</el-radio>
                        <el-radio @change="changePushRule" v-model="ruleForm.pushRule" label="USER_EVENT">基于用户事件触发</el-radio>
                     </div>
                     <div class="push-rule" v-if="ruleForm.pushRule === 'ONCE'" :style="{ marginTop: msgType === 'MASS' ? '0' : '10px' }">
                        <el-select class="width-370" v-model="ruleForm.isWaitSend" placeholder="请选择">
                           <el-option v-for="(val, key) in { NO: '立即发送', YES: '定时发送' }" :key="key" :label="val" :value="key"></el-option>
                        </el-select>
                        <div class="m-top-15" v-if="ruleForm.isWaitSend === 'YES'">
                           消息将于<el-date-picker v-model="ruleForm.timeLong" type="datetime" placeholder="选择日期时间"
                                               value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>准时发送
                           <p class="tips"><span>注：选择定时发送时，可选时段不能超过当前时点48 小时</span></p>
                        </div>
                     </div>
                     <div class="push-rule" v-else>
                        <el-row>
                           从<el-date-picker  v-model="ruleForm.beginDate" type="datetime" placeholder="选择开始时间" value-format="yyyy-MM-dd HH:mm:ss"
                        ></el-date-picker>到<el-date-picker v-model="ruleForm.endDate" type="datetime" placeholder="选择开始时间" value-format="yyyy-MM-dd HH:mm:ss"
                        ></el-date-picker>时间范围内
                        </el-row>
                        <el-row>
                           当用户做过
                           <el-select class="width-280" v-model="ruleForm.triggerId" placeholder="请选择">
                              <el-option
                                    v-for="item in targetOpt"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                              </el-option>
                           </el-select>
                        </el-row>
                        <el-row>
                           <el-select class="width-100" v-model="ruleForm.isWaitSend" placeholder="请选择">
                              <el-option
                                    v-for="(val, key) in { NO: '立即', YES: '等待' }"
                                    :key="key"
                                    :label="val"
                                    :value="key">
                              </el-option>
                           </el-select>
                           <span v-if="ruleForm.isWaitSend === 'NO'">发送</span>
                           <span v-else>
                              <el-input class="width-90" v-model="ruleForm.timeLong" placeholder="时间"></el-input>
                              <el-select class="width-90" v-model="ruleForm.timeUnit">
                                 <el-option v-for="(val, key) in { MINUTE: '分钟', HOUR: '小时', DAY: '天' }"
                                            :key="key" :label="val" :value="key">
                                 </el-option>
                              </el-select>准时发送
                              <p class="tips"><span>注：分钟最大值为2879分钟、小时最大值为47小时、天最大值为1天</span></p>
                        </span>
                        </el-row>
                     </div>
                  </el-form-item>
               </template>
            </activity-config>
         </div>
         <!-- 第二步 -->
         <div class="two-step" v-show="active === 1">
            <el-radio-group class="m-bottom-30" v-model="eventWechatDTO.type">
               <el-radio @change="changeCategories(item.key)" :label="item.key" v-for="item in filterCategories" :key="item.key">{{ item.value }}</el-radio>
            </el-radio-group>
            <el-row type="flex">
               <el-col :span="12">
                  <el-form ref="form" :model="eventWechatDTO">
                     <div v-if="eventWechatDTO.type === 'TEXT'">
                        <el-form-item label="选择类型">
                           <el-select class="width-370" v-model="eventWechatDTO.textType" placeholder="请选择" clearable>
                              <el-option v-for="(val, key) in { TEXT: '文本类型', PAGE: '可跳转落地页', APPLETS: '可跳转小程序' }"
                                          :key="key" :label="val" :value="key" :disabled="msgType === 'MASS' && (key === 'PAGE' || key === 'APPLETS')"></el-option>
                           </el-select>
                        </el-form-item>
                        <el-form-item label="消息内容">
                           <el-input class="width-370" v-model="eventWechatDTO.content" placeholder="请输入消息内容" type="textarea" :rows="4"></el-input>
                        </el-form-item>
                        <div v-if="eventWechatDTO.textType === 'PAGE'">
                           <el-form-item label="跳转地址">
                              <el-input class="width-370" v-model="eventWechatDTO.jumpPath" placeholder="跳转地址" clearable></el-input>
                           </el-form-item>
                           <p class="tips"><span>可以是任意地址，网址必须以https或者http开头。</span></p>
                        </div>
                        <div v-if="eventWechatDTO.textType === 'APPLETS'">
                           <el-form-item label="AppID">
                              <el-input class="width-370" v-model="eventWechatDTO.appId" clearable></el-input>
                              <p class="tips"><span>填写小程序appId，则表示该链接跳小程序</span></p>
                           </el-form-item>
                           <el-form-item label="Path">
                              <el-input class="width-370" v-model="eventWechatDTO.mediaPath" clearable></el-input>
                              <p class="tips"><span>填写小程序路径，路径与app.json中保持一致，可带参数</span></p>
                           </el-form-item>
                        </div>
                        <el-button type="text" style="margin-left: 12px" @click="chooseMaterial">素材中心选取</el-button>
                     </div>
                     <div v-if="eventWechatDTO.type === 'IMG'">
                        <el-form-item label="选择图片">
                           <el-input class="width-370" v-model="picName" placeholder="图片名称"></el-input>
                           <!-- <el-select class="width-370" v-model="eventWechatDTO.mediaId" placeholder="请选择">
                              <el-option
                                  v-for="item in fileOpt"
                                  :key="item.value"
                                  :label="item.label"
                                  :value="item.value">
                              </el-option>
                           </el-select> -->
                           <!-- <el-button class="bg-gradient m-left-10" @click="visibleMaterial = true; materialType = 'IMAGE'">上传图片</el-button>
                           <p class="tips"><span>图片最大为10M，支持bmp/png/jpeg/jpg/gif格式</span></p> -->
                        </el-form-item>
                        <el-button type="text" style="margin-left: 12px" @click="chooseMaterial">素材中心选取</el-button>
                     </div>
                     <div v-if="eventWechatDTO.type === 'VOICE'">
                        <el-form-item label="选择语音">
                           <el-select class="width-280" v-model="eventWechatDTO.mediaId" placeholder="请选择">
                              <el-option
                                  v-for="item in fileOpt"
                                  :key="item.value"
                                  :label="item.label"
                                  :value="item.value">
                              </el-option>
                           </el-select>
                           <el-button class="bg-gradient m-left-10">上传语音</el-button>
                           <p class="tips"><span>语音最大为2M，播放长度不超过60s，mp3/wma/wav/amr格式</span></p>
                        </el-form-item>
                     </div>
                     <div v-if="eventWechatDTO.type === 'VIDEO'">
                        <el-form-item label="选择视频">
                           <el-select class="width-280" v-model="eventWechatDTO.mediaId" placeholder="请选择">
                              <el-option
                                  v-for="item in fileOpt"
                                  :key="item.value"
                                  :label="item.label"
                                  :value="item.value">
                              </el-option>
                           </el-select>
                           <el-button class="bg-gradient m-left-10">上传视频</el-button>
                           <p class="tips"><span>视频最大为10MB，支持MP4格式</span></p>
                        </el-form-item>
                        <el-form-item label="视频标题">
                           <el-input class="width-370" v-model="eventWechatDTO.title" placeholder="视频标题"></el-input>
                        </el-form-item>
                        <el-form-item label="视频描述">
                           <el-input class="width-370" v-model="eventWechatDTO.content" placeholder="视频描述" type="textarea" :rows="4"></el-input>
                        </el-form-item>
                     </div>
                     <div v-if="eventWechatDTO.type === 'NEWS'">
                        <el-form-item label="选择类型">
                           <el-select class="width-370" v-model="eventWechatDTO.textType">
                              <el-option
                                  v-for="(val, key) in { NEWS: '点击消息跳转到图文消息', PAGE: '点击消息跳转到落地页' }"
                                  :key="key" :label="val" :value="key" :disabled="msgType === 'MASS' && key === 'PAGE'">
                              </el-option>
                           </el-select>
                        </el-form-item>
                        <div v-if="eventWechatDTO.textType === 'PAGE'">
                           <el-form-item label="图文标题">
                              <el-input class="width-370" v-model="eventWechatDTO.title" placeholder="图文标题"></el-input>
                           </el-form-item>
                           <el-form-item label="文字内容">
                              <el-input class="width-370" v-model="eventWechatDTO.content" type="textarea" :rows="4"></el-input>
                           </el-form-item>
                           <el-form-item label="封面地址">
                              <el-input class="width-370" v-model="eventWechatDTO.mediaPath" placeholder="封面地址"></el-input>
                              <p class="tips"><span>可以是任意地址，网址必须以https或者http开头。</span></p>
                           </el-form-item>
                           <el-form-item label="跳转地址">
                              <el-input class="width-370" v-model="eventWechatDTO.pagePath" placeholder="跳转地址"></el-input>
                              <p class="tips"><span>可以是任意地址，网址必须以https或者http开头。</span></p>
                           </el-form-item>
                        </div>
                        <div v-else class="news-option">
                           <el-form-item label="选择图文">
                              <el-select class="width-370" v-model="eventWechatDTO.mediaId" placeholder="请选择">
                                 <el-option
                                     v-for="item in fileOpt"
                                     :key="item.value"
                                     :label="item.label"
                                     :value="item.value">
                                 </el-option>
                              </el-select>
                           </el-form-item>
                        </div>
                     </div>
                     <div v-if="eventWechatDTO.type === 'MENU'">
                        <el-form-item label="菜单头信息">
                           <el-input class="width-370" v-model="eventWechatDTO.title" placeholder="菜单头信息" type="textarea" :rows="2"></el-input>
                        </el-form-item>
                        <el-form-item label="菜单尾信息">
                           <el-input class="width-370" v-model="eventWechatDTO.content" placeholder="菜单尾信息" type="textarea" :rows="2"></el-input>
                        </el-form-item>
                        <div class="menu-box" v-for="(item, index) in eventWechatDTO.menuList" :key="index">
                           <el-form-item label="菜单名称">
                              <el-input class="width-370" v-model="item.btnText" placeholder="菜单名称"></el-input>
                           </el-form-item>
                           <el-form-item label="用户点击菜单后发送">
                              <el-select v-model="item.type" placeholder="请选择">
                                 <el-option v-for="(val, key) in { TEXT: '文本类型', IMG: '图片类型', NEWS: '图文类型', APPLETS_CARD: '小程序卡片' }"
                                            :key="key" :label="val" :value="key"></el-option>
                              </el-select>
                           </el-form-item>
                        </div>
                        <el-button class="m-top-15 insert-btn">添加菜单</el-button>
                     </div>
                     <div v-if="eventWechatDTO.type === 'APPLETS_CARD'">
                        <el-form-item label="卡片标题">
                           <el-input class="width-370" v-model="eventWechatDTO.title" placeholder="卡片标题"></el-input>
                        </el-form-item>
                        <el-form-item label="AppID">
                           <el-input class="width-370" v-model="eventWechatDTO.appId" placeholder="AppID"></el-input>
                           <p class="tips"><span>填写小程序appId，则表示该链接跳小程序</span></p>
                        </el-form-item>
                        <el-form-item label="Path">
                           <el-input class="width-370" v-model="eventWechatDTO.mediaPath" placeholder="请填写URL"></el-input>
                           <p class="tips"><span>填写小程序路径，路径与app.json中保持一致，可带参数</span></p>
                        </el-form-item>
                        <el-form-item label="选择图片">
                           <el-select v-model="eventWechatDTO.mediaId" placeholder="请选择">
                              <el-option
                                  v-for="item in fileOpt"
                                  :key="item.value"
                                  :label="item.label"
                                  :value="item.value">
                              </el-option>
                           </el-select>
                           <el-button class="bg-gradient m-left-10" @click="visibleMaterial = true; materialType = 'IMAGE'">上传图片</el-button>
                           <p class="tips"><span>图片最大为10M，支持bmp/png/jpeg/jpg/gif格式</span></p>
                        </el-form-item>
                     </div>
                     <el-form-item class="m-top-50">
                        <el-button class="bg-gradient" @click="active --">上一步</el-button>
                        <el-button class="bg-gradient" @click="saveActivity">开始活动</el-button>
                        <el-button class="bg-gradient" type="primary" @click="saveActivity('DRAFT')">保存草稿</el-button>
                     </el-form-item>
                  </el-form>
               </el-col>
               <el-col :span="12" class="preview-box">
                  <div class="preview">
                     <el-row class="news-box" type="flex">
                        <img src="@/assets/head-portrait.png" alt="头像" width="50" height="50">
                        <img class="pic" :src="url" alt="" v-if="url&&eventWechatDTO.type === 'IMG'" width="200">
                        <p v-else>{{ `${ eventWechatDTO.content }` }}</p>
                     </el-row>
                  </div>
                  <el-button class="btn" @click="preview">预览（测试设备）</el-button>
               </el-col>
            </el-row>
         </div>
      </div>
      <!-- 上传素材 -->
      <el-dialog title="上传素材" :visible.sync="visibleMaterial" width="600px">
         <upload-material :materialType="materialType" :companyId="hotelInfo.storeId" @getMaterialId="selectMaterial"/>
      </el-dialog>
      <!-- 素材中心 -->
      <el-dialog title="素材中心" :visible.sync="show" width="860px">
         <el-row type="flex" class="material">
            <el-col class="search">
               <el-input v-model="filterText" placeholder="搜索文件夹" class="width-220" suffix-icon="el-icon-search"></el-input>
               <el-tree :data="treeData" :props="defaultProps" @node-click="handleNodeClick" class="file-tree-list"
                  highlight-current :filter-node-method="filterNode" ref="tree">
                  <span slot-scope="{ node,data }">
                     <span>
                        <i class="el-icon-folder-opened">{{ node.label }}</i>
                        <span class="text-count">({{ data.count || 0 }})</span>
                     </span>
                  </span>
               </el-tree>
            </el-col>
            <el-col class="content" v-if="eventWechatDTO.type === 'TEXT'">
               <div class="box">
                  <span class="title">文字</span>
                  <el-input v-model="title" placeholder="搜索关键词" class="width-220" suffix-icon="el-icon-search"></el-input>
               </div>
               <template v-if="materialData.length">
                  <el-radio class="text-content" v-for="(item,index) in materialData" :key="item.id" v-model="radio" :label="index" border>{{ item.title }}</el-radio>
                  <pagination :total="total" :currentPage="page" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging" />
               </template>
               <el-empty :image="require('@/assets/group_empty.png')" v-else></el-empty>
            </el-col>
            <el-col class="content" v-if="eventWechatDTO.type === 'IMG'">
               <div class="box">
                  <span class="title">图片</span>
                  <el-input v-model="title" placeholder="搜索图片名称" class="width-220" suffix-icon="el-icon-search"></el-input>
               </div>
               <template v-if="materialData.length">
                  <el-radio class="pic-content" v-for="(item,index) in materialData" :key="item.id" v-model="radio" :label="index" border>
                     <img class="pic" :src="item.filePath" alt="" width="150" height="150">
                     <div class="name">{{ item.title }}.{{ item.fileType }}</div>
                     <span class="select">选择</span>
                  </el-radio>
                  <pagination :total="total" :currentPage="page" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging" />
               </template>
               <el-empty :image="require('@/assets/group_empty.png')" v-else></el-empty>
            </el-col>
         </el-row>
         <span slot="footer" class="dialog-footer">
            <el-button @click="show = false">取 消</el-button>
            <el-button type="primary" @click="confirm" :disabled="!materialData.length">确 定</el-button>
         </span>
      </el-dialog>
   </div>
</template>
<script>
import { getMaterialClassify, getMaterialContent } from '@/api/smtech/materialCenter'
import { urlObj  } from '@/api/interface';
import { mapState } from 'vuex';
import activityConfig from '@/components/local/activityConfig';
import uploadMaterial from '@/components/local/uploadMaterial';
import { writing } from "@/api/interface/business";
import { operate } from  "@/api/interface/smtech";
import { dateFactory } from '@/common/js/common';
export default {
   components: {
      activityConfig, uploadMaterial
   },
   data() {
      return {
         action: '',
         msgType: '',
         active: 0,
         targetOpt: [],
         contCategories: [
            { key: 'TEXT', value: '文本', msgType: 'CUSTOMER_MASS' },
            { key: 'IMG', value: '图片', msgType: 'CUSTOMER_MASS' },
            { key: 'VOICE',value: '语音', msgType: 'CUSTOMER_MASS' },
            { key: 'VIDEO', value: '视频', msgType: 'CUSTOMER_MASS' },
            { key: 'NEWS', value: '图文', msgType: 'CUSTOMER_MASS' },
            { key: 'MENU', value: '菜单', msgType: 'CUSTOMER' },
            { key: 'APPLETS_CARD', value: '小程序卡片', msgType: 'CUSTOMER' }
         ],
         fileOpt: [],
         visibleMaterial: false,
         materialType: '',
         ruleForm: {
            pushRule: 'ONCE',
            isWaitSend: 'NO',
            beginDate: '',
            endDate: '',
            timeLong: '',
            timeUnit: '',
            triggerId: '',
         },
         eventWechatDTO: {
            type: 'TEXT',
            mediaId: '',
            title: '',
            appId: '',
            textType: 'TEXT',
            content: '',
            mediaPath: '',
            pagePath: '',
            jumpPath: 'https://www.',
            menuList: [{}]
         },
         editId:'',
         state:'IN_PROGRESS',

         // 素材中心弹窗
         show: false,
         treeData: [],
         materialData: [],
         defaultProps: {
            children: 'children',
            label: 'name',
         },
         activeData: {},
         filterText:'',
         title: '',
         picName:'',
         radio:'',
         url:'',
         /** 分页 */
         total: 1, // 用户列表总条目数
         page: 1, // 当前页 默认第一页
         limit: 10, // 每页显示数
      }
   },
   computed: {
      ...mapState(['hotelInfo']),
      filterCategories() {
         return this.contCategories.filter(item => item.msgType.indexOf(this.msgType) >= 0)
      }
   },
   watch: {
      filterText(val) {
        this.$refs.tree.filter(val)
      },
      title() {
         this.page = 1
        this.getData()
      },
   },
   mounted() {
      Object.assign(this, this.$route.query)
      this.action === 'edit' && this.getEditInfo()
      this.action === 'copy' && this.getEditInfo()
   },
   beforeDestroy() {
      sessionStorage.removeItem('activityObj')
   },
   methods: {
      // 获取编辑信息
      getEditInfo() {
         const { eventWechatDTO, ...activityObj } = JSON.parse(sessionStorage.getItem('activityObj') || '{}')
         for (const k in this.ruleForm) k in activityObj && (this.ruleForm[k] = activityObj[k])
         for (const k in this.eventWechatDTO) k in eventWechatDTO && (this.$nextTick(()=>{this.eventWechatDTO[k] = eventWechatDTO[k]}))
         this.$nextTick(()=>{
            let data = this.eventWechatDTO.content
            this.eventWechatDTO.jumpPath = data.substring(data.indexOf('href="')+6,data.indexOf('">'))
            this.eventWechatDTO.appId = data.substring(data.indexOf('appid=')+6,data.indexOf('data-miniprogram-path='))
            this.eventWechatDTO.mediaPath =  data.substring(data.indexOf('path=')+5,data.indexOf('>'))
         })
         for (const k in this.eventWechatDTO){
            if(k === 'mediaId') this.getFileList("IMG")
         }
         this.editId = activityObj.id
         this.state = activityObj.state
         this.ruleForm.pushRule === 'USER_EVENT' && this.getTargetList()
      },
      // 改变推送规则
      changePushRule(val) {
         this.ruleForm.timeLong = ''
         val === 'USER_EVENT' && this.getTargetList()
      },
      // 获取触发事件列表
      getTargetList() {
         if (this.targetOpt.length > 0) return
         const url = urlObj.metadataList
         const param = { category: 'TRIGGER_EVENT', state: true }
         this.$axios.post(url, param).then(res => {
            res.success && (this.targetOpt = res.records.map(item => ({ label: item.name, value: item.id })))
         })
      },
      // 检验活动名称是否重复
      checkNameIsExist() {
         return new Promise((resolve) => {
            const url = operate.checkNameIsExist
            const param = { eventName: this.ruleForm.eventName, companyId: this.hotelInfo.storeId }
            this.$axios.post(url, param).then(res => {
               if (!res.success) return resolve(true);
               resolve(res.records)
            })
         })
      },
      // 下一步
      async nextStep(ruleForm) {
         this.ruleForm = Object.assign({}, this.ruleForm, ruleForm)
         if(this.action !== 'edit'){
            if (await this.checkNameIsExist()) {
               return this.$message.error('活动名称不能重复')
            }
         }
         if(this.ruleForm.pushRule === 'USER_EVENT') {
            if(dateFactory.getDistanceDays(this.ruleForm.beginDate,this.ruleForm.endDate,true) <=0){
               return this.$message.error('后一个时间不可先于前一个时间')
            }
         }
         this.active ++
      },
      // 改变消息类别
      changeCategories(type) {
         this.eventWechatDTO.textType = type
         this.eventWechatDTO.mediaId = ''
         this.eventWechatDTO.appId = ''
         this.eventWechatDTO.mediaPath = ''
         this.eventWechatDTO.title  = ''
         this.eventWechatDTO.content = ''
         this.getFileList(type)
      },
      // 获取文件列表
      getFileList(type) {
         if (type === 'IMG' || type === 'APPLETS_CARD') type = 'IMAGE'
         if (type !== 'IMAGE' && type !== 'VIDEO' && type !== 'NEWS' && type !== 'VOICE') return
         let url = writing.materialList
         const param = { companyId: this.hotelInfo.storeId, type }
         if((type === 'VOICE' && this.msgType === 'CUSTOMER') || (type === 'NEWS' && this.msgType === 'MASS')) {
            param.type = type.toLocaleLowerCase()
            param.count = 20
            param.offset = 0
            url = writing.getFileList
         }
         this.$axios.get(url, param).then(res => {
            if (!res.success) return
            const data = res.records.item || res.records
            this.fileOpt = data.map(item => {
               return { label: item.title || item.name || item.content.news_item[0].title.substr(0, 20) || '', value: item.media_id || item.id || '' }
            })
         })
      },
      // 选中当前上传素材
      selectMaterial(id, type, visible) {
         this.visibleMaterial = visible
         this.eventWechatDTO.mediaId = id
         this.getFileList(type)
      },
      // 预览设备
      preview() {
         if (this.eventWechatDTO.type === 'TEXT') {
            if (this.eventWechatDTO.textType === 'PAGE') this.eventWechatDTO.content = `${ this.eventWechatDTO.content }<a href="${ this.eventWechatDTO.jumpPath }">查看详情</a>`
            if (this.eventWechatDTO.textType === 'APPLETS') this.eventWechatDTO.content = `${ this.eventWechatDTO.content }<a href="http://www.qq.com" data-miniprogram-appid=${ this.eventWechatDTO.appId } data-miniprogram-path=${ this.eventWechatDTO.mediaPath }>查看详情</a>`
         }
      },
      // 开始活动
      saveActivity(state) {
         this.preview()
         if (state === 'DRAFT') this.state = state
         let url = urlObj.createAmass
         const param = {
            hotelId: this.hotelInfo.id,
            companyId: this.hotelInfo.storeId,
            platform: 'PUBLICACCOUNT',
            state: this.state,
            eventName: this.ruleForm.eventName,
            isConvert: this.ruleForm.isConvert,
            pushRule: this.ruleForm.pushRule,
            isWaitSend: this.ruleForm.isWaitSend,
            'eventWechatDTO.type': this.eventWechatDTO.type,
         }
         if(this.action === 'edit' && state !== 'DRAFT') {
            url = operate.editActivity
            param.id = this.editId
         }
         this.msgType && Object.assign(param, { 'eventWechatDTO.msgType': this.msgType });
         // 第一步
         !(this.ruleForm.checked) && (param.groupIds = this.ruleForm.groupList.map(item => item.groupId).toString())
         param.isConvert === 'YES' && Object.assign(param, { targetId: this.ruleForm.targetId, days: this.ruleForm.days })
         if (param.pushRule === 'ONCE' ) param.isWaitSend === 'YES' && Object.assign(param, { timeLong: this.ruleForm.timeLong })
         else {
            Object.assign(param, { beginDate: this.ruleForm.beginDate, endDate: this.ruleForm.endDate,triggerId: this.ruleForm.triggerId })
            if (param.isWaitSend === 'YES') Object.assign(param, {timeLong: this.ruleForm.timeLong, timeUnit: this.ruleForm.timeUnit })
         }
         // 第二步
         if (param['eventWechatDTO.type'] === 'TEXT') {
            Object.assign(param, { 'eventWechatDTO.textType': this.eventWechatDTO.textType,
               'eventWechatDTO.content': this.eventWechatDTO.content })
         }
         if (param['eventWechatDTO.type'] === 'IMG' || param['eventWechatDTO.type'] === 'VOICE') {
            Object.assign(param, { 'eventWechatDTO.mediaId': this.eventWechatDTO.mediaId })
         }
         if (param['eventWechatDTO.type'] === 'VIDEO') {
            Object.assign(param, { 'eventWechatDTO.mediaId': this.eventWechatDTO.mediaId,
               'eventWechatDTO.title': this.eventWechatDTO.title, 'eventWechatDTO.content': this.eventWechatDTO.content })
         }
         if (param['eventWechatDTO.type'] === 'NEWS') {
            Object.assign(param, { 'eventWechatDTO.textType': this.eventWechatDTO.textType })
            if (param['eventWechatDTO.textType'] === 'PAGE') {
               Object.assign(param, { 'eventWechatDTO.title': this.eventWechatDTO.title,
               'eventWechatDTO.content': this.eventWechatDTO.content, 'eventWechatDTO.mediaPath': this.eventWechatDTO.mediaPath,
               'eventWechatDTO.pagePath': this.eventWechatDTO.pagePath })
            } Object.assign(param, { 'eventWechatDTO.mediaId': this.eventWechatDTO.mediaId })
         }
         if (param['eventWechatDTO.type'] === 'MENU') {
            Object.assign(param, { 'eventWechatDTO.title': this.eventWechatDTO.title,
               'eventWechatDTO.content': this.eventWechatDTO.content, 'eventWechatDTO.menuList': this.eventWechatDTO.menuList })
         }
         if (param['eventWechatDTO.type'] === 'APPLETS_CARD') {
            Object.assign(param, { 'eventWechatDTO.title': this.eventWechatDTO.title,
               'eventWechatDTO.appId': this.eventWechatDTO.appId, 'eventWechatDTO.mediaPath': this.eventWechatDTO.mediaPath,
               'eventWechatDTO.mediaId': this.eventWechatDTO.mediaId })
         }
         this.$axios.post(url, param).then(res => {
            if (!res.success) return
            this.$message({ message: '创建成功！', type: 'success' })
            this.$router.push({ path: '/activity_list/PUBLICACCOUNT', query: { msgType: this.msgType } })
         })
      },
      // 素材中心弹窗
      chooseMaterial(){
         this.show = true
         this.title = ''
         this.filterText = ''
         this.page = 1,
         this.limit = 10,
         this.getClassifyData()
      },
      // 文件夹列表
      getClassifyData() {
         let data = new FormData()
         if (this.eventWechatDTO.type === 'TEXT') {
            data.append('type', 'VERBAL_TRICK')
         } else if (this.eventWechatDTO.type === 'IMG') {
            data.append('type', 'IMG')
         }
         data.append('hotelId', this.hotelInfo.id)
         data.append('companyId', this.hotelInfo.storeId)
         getMaterialClassify(data).then(({ success, records }) => {
            if (success) {
               let obj = []
               for (let i = 0; i < records.length; i++) {
               records[i].value = records[i].id
               records[i].label = records[i].name
               if (records[i].parentId === undefined) {
                  obj.push(records[i])
               }
               }
               for (let i = 0; i < records.length; i++) {
               if (records[i].parentId !== undefined) {
                  delete records[i].children
                  for (let j = 0; j < obj.length; j++) {
                     if (records[i].parentId === obj[j].id) {
                     obj[j].children.push(records[i])
                     }
                  }
               }
               }
               this.treeData = obj
               this.handleNodeClick(obj[0])
            }
         })
      },
      // 素材列表
      getData() {
         let data = new FormData()
         if (this.eventWechatDTO.type === 'TEXT') {
            data.append('type', 'VERBAL_TRICK')
         } else if (this.eventWechatDTO.type === 'IMG') {
            data.append('type', 'IMG')
         }
         data.append('page', this.page)
         data.append('limit', this.limit)
         data.append('classifyId', this.activeData.id)
         if (this.title) {
            data.append('title', this.title)
         }
         getMaterialContent(data).then(({ success, records, total }) => {
            if (success) {
               this.materialData = records
               this.total = total
               this.loading = false
            }
         })
      },
      handleNodeClick(data) {
         this.activeData = { ...data }
         this.getData()
      },
      filterNode(value, data) {
        if (!value) return true
        return data.label.indexOf(value) !== -1
      },
      // 确定
      confirm(){
         if (this.eventWechatDTO.type === 'TEXT') {
            this.eventWechatDTO.content = this.materialData[this.radio].title
         } else if (this.eventWechatDTO.type === 'IMG') {
            this.picName = this.materialData[this.radio].title
            this.eventWechatDTO.mediaId = this.materialData[this.radio].id
            this.url = this.materialData[this.radio].filePath
         }
         this.show = false
      },
      // 改变每页数
      pageChange(num) {
        this.limit = num
        this.getData()
      },
      // 改变当前页
      handlePaging(num) {
        this.page = num
        this.getData()
      },
   }
}
</script>
<style scoped lang="scss">
.cont {
   ::v-deep .el-form-item__label { width: 80px }
   .crumbs-box .el-button{ float: right }
   .tips{
      margin: 10px 0 0 80px;
      span{
         padding: 6px 10px; color: #c0c4cc; background-color: #f5f7fa;
         border: 1px solid #e4e7ed; line-height: 30px; font-size: 13px;
      }
   }
   .first-step{
      .push-rule{
         margin: 10px 0 0 70px;
         .el-date-editor{ margin: 0 8px }
         .el-select{ margin: 0 8px }
         > .el-row{ margin: 0 0 20px 0 }
      }
   }
   .two-step{
      .menu-box{ border: solid 1px #e5e5e5; width: 500px; padding: 20px 20px 0 20px }
      .insert-btn{ width: 500px }
      .preview-box{
         background-color: #e5e5e5; padding: 20px 0; text-align: center;
         .preview{
            width: 333px; height: 615px; margin: 0 auto; overflow: hidden;
            background: url('../../../../assets/preview-img-bg.png') no-repeat left top / 100% 100%;
            .news-box {
               margin: 110px 0 0 0; padding: 0 10px; max-height: 436px; overflow-y: hidden;
               p{
                  padding: 10px; font-size: 12px; color: #999999; background: #FFFFFF;
                  border-radius: 10px; border: #e5e5e5 1px solid; margin-left: 5px;
                  line-height: 21px; text-align: left;
               }
               .pic {
                  padding: 10px;
                  font-size: 12px;
                  color: #999999;
                  background: #FFFFFF;
                  border-radius: 10px;
                  border: #e5e5e5 1px solid;
                  margin-left: 5px;
               }
            }
         }
         .btn { margin: 20px auto 0 }
      }
   }
   .material{
      .search{
         width: 262px;
         padding: 10px 20px;
         border: 1px solid #d7d7d7;
         .file-tree-list {
            margin-top: 16px;
            .el-icon-folder-opened {
               margin-right: 5px;
            }
         }
      }
      .content{
         padding: 20px 20px 0;
         .box{
            display: flex;
            flex-wrap: wrap;
            align-items: baseline;
            justify-content: space-between;
            .title{
               width: 50%;
            }
         }
         .text-content{
            width: 47%;
            height: 60px;
            margin-top: 20px;
            margin-left: 0;
            border-radius: 4px;
            border: 1px solid #999;
            /deep/ .el-radio__input{
               line-height: 42px;
            }
            &:nth-child(2n+1){
               margin-right: 0;
            }
            /deep/ .el-radio__label{
               position: absolute;
               top: 10px;
               left: 20px;
               width: 200px;
               text-align: justify;
               overflow: hidden;
               white-space: pre-wrap;
               display: -webkit-box;
               -webkit-line-clamp: 3;
               -webkit-box-orient: vertical;
               text-overflow: ellipsis;
            }
         }
         .pic-content{
            width: 152px;
            overflow: hidden;
            margin: 20px 31px 5px 0;
            border-radius: 4px;
            border: 1px solid #999;
            position: relative;
            height: 180px;
            padding: 0;
            &:nth-child(3n+1){
               margin-right: 0;
            }
            /deep/ .el-radio__input{
               position: absolute;
               bottom: 3px;
               right: 42px;
            }
            /deep/ .el-radio__label{
               .pic {
                  margin-left: -10px;
               }
               .name {
                  width: 80px;
                  overflow: hidden;
                  position: absolute;
                  bottom: 5px;
                  left: 10px;
               }
               .select{
                  position: absolute;
                  bottom: 5px;
                  right: 10px;
               }
            }
            &:hover{
               border-color: #678ff4;
               background-color: #eff3fd;
            }
         }
      }
   }
}
</style>
