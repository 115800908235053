<template>
   <el-form class="material-box" ref="ruleForm" :model="ruleForm" :rules="rules">
      <el-form-item label="选择文件类型">
         <el-select class="width-280" v-model="ruleForm.type" placeholder="请选择">
            <el-option v-for="(val, key) in { IMAGE: '图片', VIDEO: '视频' }"
                        :key="key" :label="val" :value="key"></el-option>
         </el-select>
      </el-form-item>
      <el-form-item label="文件命名" prop="title">
         <el-input class="width-280" v-model="ruleForm.title" clearable placeholder="文件命名"></el-input>
      </el-form-item>
      <el-form-item label="选择本地文件并上传" prop="file">
         <el-upload name="file" class="upload-file" :auto-upload="false" action="#" :multiple="false"
                    :on-change="changeFile" :limit="1">
            <el-button>点击上传</el-button>
         </el-upload>
      </el-form-item>
      <el-form-item class="upload-btn">
         <el-button class="bg-gradient m-top-15" @click="saveUpload">确定上传</el-button>
      </el-form-item>
   </el-form>
</template>
<script>
import { urlObj } from "@/api/interface";
import { writing } from "@/api/interface/business";
import { beforeUpload } from "@/common/js/common";
export default {
   props: ['materialType', 'companyId'],
   data() {
      return {
         ruleForm: {
            type: this.materialType,
            title: '',
            companyId: this.companyId,
            status: 'EFFECTIVE',
            file: null,
            'ossFileParam.module': 'material',
            'ossFileParam.menu': '',
            'ossFileParam.category': 'media',
            'ossFileParam.func': 'image',
         },
         rules: {
            title: [{ required: true, message: '文件名不能为空！',trigger: 'blur' }],
            file: [{ required: true, message: '请上传素材！', trigger: 'change' }]
         }
      }
   },
   mounted() {
      this.ruleForm['ossFileParam.menu'] = JSON.parse(sessionStorage.getItem('userInfo') || '{}').userName
   },
   methods: {
      // 上传素材
      changeFile(file) {
         let error = beforeUpload(file, 10)
         if (error) return this.$message.error(error)
         this.ruleForm.file = file.raw
      },
      // 保存上传
      saveUpload() {
         this.$refs.ruleForm.validate(valid => {
            if (!valid) return
            const url = writing.createMaterial
            const param = new FormData()
            for (const k in this.ruleForm) param.append(k, this.ruleForm[k])
            this.$axios.post(url, param, 'file').then(res => {
               if (!res.success) return
               this.$message({ message: '上传素材成功！', type: 'success' })
               this.$emit('getMaterialId', res.records.id, this.ruleForm.type)
            })
         })
      }
   }
}
</script>
<style lang="scss" scoped>
.material-box{
   .upload-btn{ text-align: right }
   ::v-deep .el-form-item__label{ width: 150px!important; }
   ::v-deep .el-form-item__error{ left: 150px }
}
</style>
